import Countdown from "react-countdown";
import { ArrowLeft } from "react-feather";
import { Link } from "react-router-dom";

const Newunitypro = () => {
  
  return (
    <>
      <div className="text-left bg-amber-400 rounded-xl px-5 py-4 text-gray-900 shadow-md">
          <div className="bg-gray-900 inline-block px-4 py-3 rounded-lg">
              <img src="https://i.ibb.co/s1fR7dy/PRO-LOGO.png" alt="logo" className="w-[150px] mx-auto" />
          </div>
          <h2 className="text-xl mb-2 mt-4 font-bold text-gray-900">NEWUNITY PRO is LIVE!</h2>
          <p className="text-md leading-5 mb-4">Take advantage now. </p>
          
          <div className="pt-5 flex gap-3"> 
            <a href='https://pro.newunity.io' target="blank">
                  <div className="text-sm mb-3 bg-green-500 text-white rounded-10 px-5 py-4 font-bold animate-pulse">
                      GO TO NEWUNITY PRO
                  </div>
              </a>
          </div>
      </div>
      <Link to={'/dashboard'}><p className="text-gray-700 mt-4 text-sm flex items-center gap-2"><ArrowLeft size={15} />Go to Newunity dashboard</p></Link>
    </>
  );
};
export default Newunitypro;

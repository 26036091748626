import {
  Button,
  Card,
  List,
  ListItem,
  ListItemPrefix
} from "@material-tailwind/react";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import i1 from "../../Assets/i-1.svg";
import i2 from "../../Assets/i-2.svg";
import i3 from "../../Assets/i-3.svg";
import i4 from "../../Assets/i-4.svg";
import i6 from "../../Assets/i-6.svg";
import i7 from "../../Assets/i-7.svg";
import i8 from "../../Assets/i-8.svg";
import i9 from "../../Assets/i-9.svg";
import logo from "../../Assets/logo.png";
import { useUserAuth } from "../../Contexts/authContext";
import { useDeviceData } from "../../Hooks/deviceData";
import { useHelpers } from "../../Hooks/helpers";
import tg from "../../Assets/telegram.png";
import yt from "../../Assets/youtube.png";
import pdf from "../../Assets/pdf.png";
import appConfig from "../../Utility/AppConfig";
import { Box, DollarSign, PlusCircle } from "react-feather";

const Sidebar = ({ close }) => {

  const device = useDeviceData();
  const auth = useUserAuth();
  const helper = useHelpers();
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(0);
  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  return (
    <Card className="sidebar min-h-[calc(100vh-5rem)] w-full pr-6 max-w-[20rem] bg-richBlack overflow-y-auto">
      <div className="mb-4 mt-3 px-3">
        <img className="w-[210px]" src={logo} alt="brand" />
      </div>
      <List className="p-0 sidebar-list mb-5">
        <Link to={'/dashboard'} onClick={close}>
          <ListItem className="list-item">
            <ListItemPrefix>
              <img src={i1} alt="i" />
            </ListItemPrefix>
            Dashboard
          </ListItem>
        </Link>
        <Link to={'/purchase'} onClick={close}>
          <ListItem className="list-item">
            <ListItemPrefix>
              <img src={i2} alt="i" />
            </ListItemPrefix>
            Contribute Entries
          </ListItem>
        </Link>
        <Link to={'/topup'} onClick={close}>
          <ListItem className="list-item">
            <ListItemPrefix>
              <PlusCircle />
            </ListItemPrefix>
            Topup Re-Contribution Wallet
          </ListItem>
        </Link>
        <Link to={'/withdraw'} onClick={close}>
          <ListItem className="list-item">
            <ListItemPrefix>
              <img src={i4} alt="i" />
            </ListItemPrefix>
            Claim Donations
          </ListItem>
        </Link>
        <Link to={'/entries'} onClick={close}>
          <ListItem className="list-item">
            <ListItemPrefix>
              <img src={i3} alt="i" />
            </ListItemPrefix>
            My Entries
          </ListItem>
        </Link>
        <Link to={'/payments'} onClick={close}>
          <ListItem className="list-item">
            <ListItemPrefix>
              <img src={i6} alt="i" />
            </ListItemPrefix>
            Donations Received
          </ListItem>
        </Link>
        <Link to={'/referrals'} onClick={close}>
          <ListItem className="list-item">
            <ListItemPrefix>
              <img src={i8} alt="i" />
            </ListItemPrefix>
            My Referrals
          </ListItem>
        </Link>
        <Link to={'/products'} onClick={close}>
          <ListItem className="list-item">
            <ListItemPrefix>
              <Box />
            </ListItemPrefix>
            Products
          </ListItem>
        </Link>
        {/* <Link to={'/bonuspool'} onClick={close}>
          <ListItem className="list-item">
            <ListItemPrefix>
              <DollarSign />
            </ListItemPrefix>
            Bonus Pool <span className="bg-red-700 rounded-10 px-2 py-[2px] leading-0 font-bold text-[11px] ml-2 animate-pulse">NEW</span>
          </ListItem>
        </Link> */}
        <Link to={'/activities'} onClick={close}>
          <ListItem className="list-item">
            <ListItemPrefix>
              <img src={i6} alt="i" />
            </ListItemPrefix>
            Recent Activity
          </ListItem>
        </Link>
        <a href={appConfig.support} target="blank">
          <ListItem className="list-item">
            <ListItemPrefix>
              <img src={i7} alt="i" />
            </ListItemPrefix>
            Contact Support
          </ListItem>
        </a>
        <ListItem onClick={async () => { device.deleteToken(); await auth.bootApp(); window.location.reload(); }} className="list-item">
          <ListItemPrefix>
            <img src={i9} alt="i" />
          </ListItemPrefix>
          Log out
        </ListItem>
      </List>
      <div className="px-3 py-5">
        <a href={appConfig.pdf_complan} target="blank">
          <div className="flex justify-start gap-2 text-sm items-center mb-3 bg-green-900 text-white rounded-10 px-3 py-2 font-bold">
            <img src={pdf} className="w-10" /> <p>View Complan PDF</p>
         </div>
        </a>
        <a href={appConfig.youtube_complan} target="blank">
          <div className="flex justify-start gap-2 text-sm items-center mb-3 bg-blue-800 text-white rounded-10 px-3 py-2 font-bold">
            <img src={yt} className="w-10" /> <p>Watch Complan Video</p>
          </div>
        </a>
        <a href={appConfig.telgram} target="blank">
          <div className="flex justify-start gap-2 text-sm items-center mb-3 bg-indigo-800 text-white rounded-10 px-3 py-2 font-bold">
            <img src={tg} className="w-10" /><p>Join Telegram Group</p>
          </div>
        </a>
      </div>
    </Card>
  );
};

export default Sidebar;

import React from "react";
import arwLeftSqure from "../../Assets/arw-left-square.svg";
import imgOne from "../../Assets/authlogin.png";
import logo from "../../Assets/logo.png";
import AppRouter from "../../Routers/AppRouter";
import appConfig from "../../Utility/AppConfig";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "./Footer";

const AuthLayout = () => {

  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="min-h-screen relative bg-white">
      <main className="xl:grid grid-cols-2 items-center relative min-h-screen">
        <div className="h-1/5 w-full xl:h-screen xl:w-1/2 xl:absolute right-0 top-0 bottom-0 xl:p-7">
          <div className="bg-[#A1CDFF] grid place-content-center p-6 h-full xl:rounded-[50px]"
          style={{ backgroundImage: `url(${imgOne})`, backgroundSize: 'cover', backgroundPosition: 'center center' }}>
            {(location.pathname.includes('login') || location.pathname.includes('register')) &&
              <div className="bg-white/90 px-6 py-5 rounded-10">
                <img src={logo} className="max-w-[250px] mx-auto" />
                <div className="bg-blue-400 px-5 py-2 mt-4 shadow-md rounded-10 text-white font-bold cursor-pointer">
                  {location.pathname.includes('login') && 
                    <h3 className="text-center leading-5" onClick={() => navigate('/register')}>Don't have an account? Register now.</h3>
                  }
                  {location.pathname.includes('register') && 
                    <h3 className="text-center leading-5" onClick={() => navigate('/login')}>Already registered? Login now.</h3>
                  }
                </div>
              </div>
            }
          </div>
        </div>
        <div className="px-5 py-8 lg:px-14 xl:px-20 lg:py-14">
          <a
            href={appConfig.websiteLink}
            className="text-gray font-medium text-lg inline-flex items-center gap-2 mb-6 lg:mb-10 xl:mb-14"
          >
            <img src={arwLeftSqure} alt="<" />
            Back to Home
          </a>
          <a href="https://pro.newunity.io/" target="blank">
            <div className="bg-green-500 text-white font-bold px-3 py-2 rounded-10 mb-4 animate-pulse">
              Want to register into the new NEWUNITY PRO platform? Click here to go to official website.
            </div>
          </a>
          <AppRouter />
        </div>
      </main>
    </div>
  );
};

export default AuthLayout;
